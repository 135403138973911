import { HomeAdministracion } from '../pages/HomeAdministracion'
import { RouterRequisionGeneralMateriales } from '../../general/pages/requisicion_general_materiales/RouterRequisionGeneralMateriales'

export const RouterAdministracion = [
  {
    path: '',
    element: <HomeAdministracion />
  },
  {
    path: 'requisicion-general',
    children: RouterRequisionGeneralMateriales
  }
]
