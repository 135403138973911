import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo-oficial.png'
import { useAuth } from '../../hooks/useAuth'

const NavAdministracion = () => {
  const { logout } = useAuth()
  
  const logoutUser = () => {
    // cerramos sesion
    logout()
  }

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        {/* Logo */}
        <Link className="navbar-brand" to={'/administracion'}>
          <img
            src={logo}
            alt="Logo"
            width="70"
            height="60"
            className="d-inline-block align-text-top"
          />
        </Link>

        {/* Toggler for small screens */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar content */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <button onClick={logoutUser} className="btn btn-outline-danger">
                Cerrar Sesión
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavAdministracion
